// @font-face {
//   font-family: "Omnes";
//   src: local("Omnes Semibold"),
//     url(./assets/fonts/Omnes\ Semibold\ Regular.ttf) format("truetype");
// }

// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap");

:root {
  --white: #fff;
  --light-grey: #f5f5f5;
  --light-green: #22ad87;
  --esteem-green: #009877;
  --dark-green: #008264;
  --dark-grey: #202020;
  --black: #000;
}

* {
  font-family: "Inter", sans-serif !important;
  scroll-behavior: smooth;
  box-shadow: none !important;
}

.Body {
  -webkit-animation: fadein 0.5s ease; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s ease; /* Firefox < 16 */
  -ms-animation: fadein 0.5s ease; /* Internet Explorer */
  -o-animation: fadein 0.5s ease; /* Opera < 12.1 */
  animation: fadein 0.5s ease;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

$white: "#ffffff";
$grey: "#f5f5f5";
$lightGreen: "#22AD87";
$esteemGreen: "#009877";
$darkGreen: "#008264";
$darkGrey: "#202020";
$black: "#000000";

* {
  box-sizing: border-box;
  margin: auto;
}

header img {
  height: 70px;
}

a {
  font-weight: bold;
  text-decoration: underline;
  color: #fff;
  transition: color 0.2s ease;
}

// a:hover {
// color: #770098;
// }

h2 {
  padding: 0 10px;
}

.width1250 {
  max-width: 1250px !important;
}

.Body {
  position: relative;
  // height: 100vh;
  // flex-grow: 1;
  overflow-x: hidden;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

li {
  text-align: left;
}

.App-header {
  background-image: url("./assets/images/IMG_7641.jpg");
  // background-color: var(--esteem-green);
  // background: linear-gradient(
  //   45deg,
  //   rgba(0, 152, 119, 1) 0%,
  //   rgba(34, 173, 135, 1) 100%
  // );
  // background-color: #009877;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position-y: 75%;
  background-position-x: 50%;
  background-attachment: fixed;

  // height: 75vh;
  //   height: max-content;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}
video#bgVideo {
  position: absolute; // Play the video in full screen mode
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  z-index: 0; // Put the video behind all other elements
}
.MuiTypography-body2 {
  padding: 0 25px;
}
input {
  padding: 10px;
  border: 2px solid var(--esteem-green);
  border-radius: 20px;
  margin: 5px;
  outline: none;
  width: 100%;
}
textarea {
  padding: 10px;
  border: 2px solid var(--esteem-green);
  border-radius: 20px;
  margin: 5px;
  outline: none;
  min-height: 10em;
  width: 100%;
}
.landing-bottom {
  // background-color: var(--esteem-green);
  display: flex;
}

.landing-bottom .shape-fill {
  fill: var(--white);
}

.Timeline {
  background-color: var(--light-grey);
  padding: 100px 0 150px 0;
}

.Nav-button {
  size: large;
}

.wwd-block {
  max-width: 1250px;
}

.Landing-Section1 {
  background-color: var(--white);
  padding: 25px 0 100px 0;
}

.Landing-Section2 {
  background-color: var(--white);
  padding: 100px 0 150px 0;
}

.Landing-Section3 {
  background-color: var(--light-grey);
  padding: 100px 0 150px 0;
}

#footer {
  padding: 50px;
}

@media screen and (min-width: 900px) {
  #footer {
    padding: 0;
  }
}

.footer-top .shape-fill {
  fill: var(--dark-grey);
}

.footer-top {
  // height: 52px;
  display: flex;
  background-color: var(--light-grey);
}

.footer-logos {
  margin: 0 25px;
  height: 100px;
  // max-height: 100px;
  width: auto;
}

#hw-logo {
  height: 75px;
}

#sdme-logo {
  height: 120px;
}

.Footer {
  background-color: var(--dark-grey);
  padding: 0 0 10px 0;
}

.landing-img {
  border-radius: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
}

.wwd-block {
  // background-color: #e7e3e3;
  // padding: 50px;
  border-radius: 20px;
}

.mission-block {
  background-color: #202020;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 3px 0 #0c0c0c;
}

.pillar-block-1 {
  background-color: #202020;
  padding: 50px;
  border-radius: 1em;
  box-shadow: 0 3px 0 #0c0c0c;
}

.pillar-block-2 {
  background-color: #202020;
  padding: 50px;
  border-radius: 1em;
  box-shadow: 0 3px 0 #0c0c0c;
}

.team-block {
  background-color: #202020;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 3px 0 #0c0c0c;
  margin: 30px;
  align-items: center;
  min-width: 200px;
}

.team-block-1 {
  background-color: #202020;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 3px 0 #0c0c0c;
  margin: 30px;
}

.team-block-2 {
  margin: 20px;
}

.team-block:hover {
  cursor: pointer;
  transition: 0.6;
  animation: sizeUp 0.6s forwards;
}

.team-block-1:hover {
  cursor: pointer;
  transition: 0.6;
  animation: sizeUp 0.6s forwards;
}

.team-card {
  border-radius: 20px;
  width: 500;
  height: 500;
  background: "#1b1b1b";
  transition: 0.1;
  animation: sizeDown 0.3s backwards;
}

.team-card:hover {
  cursor: auto;
  transition: 0.3;
  animation: sizeUp 0.7s forwards;
}
.team-image {
  filter: esteemgreen;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  transition: all 300ms ease;
  width: 200;
  height: 200;
  display: flex;
  object-fit: cover;
}

.team-image:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

@keyframes sizeUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

@keyframes sizeDown {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.timeline-bottom {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: 0;
}

.timeline-bottom svg {
  position: relative;
  display: block;
  // width: calc(100% + 1.3px);
  width: 100%;
  height: 100px;
  transform: rotateY(180deg);
}

.timeline-bottom,
.shape-fill {
  fill: var(--white);
  background-color: var(--light-grey);
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 5px;
  // background: var(--esteem-green);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 152, 119, 1) 1%,
    rgba(0, 152, 119, 1) 99%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
}

.vertical-timeline-element-content {
  background-color: var(--esteem-green) !important;
  box-shadow: none !important;
}

.vertical-timeline-element-title {
  color: var(--white);
  font-size: 2em !important;
}

.vertical-timeline-element-subtitle {
  color: var(--light-grey);
}

.vertical-timeline-element-icon {
  background-color: var(--esteem-green) !important;
  box-shadow: none !important;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .timeline-bottom svg {
    width: calc(150%);
    height: 83px;
  }
}

.impact {
  background-color: #e7e3e3;
  height: 100%;
  white-space: nowrap;
}

.impact-block-1 {
  background-color: #e7e3e3;
  padding: 50px;
  border-radius: 20px;
}

.pillar-list {
  margin: 0px;
  padding: 0px;
  overflow: scroll;
}

.pillar-details-1 {
  padding: 130px;
  background-color: light-grey;
  max-width: 1250px;
}

.pillar-item {
  padding-top: 100px;
  text-align: center;
  justify-content: center;
  width: 300px;
  height: 400px;
  display: inline-block;
  transition: all 0.5s ease;
  font-size: 25px;
  color: white;
}

.pillar-item:hover {
  transition: all 0.5s ease;
  font-size: 1.75vw;
  width: 15vw;
  cursor: pointer;
}

#pillar-0 {
  background-image: url();
  background-size: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/images/sustainability.png");
}

#pillar-1 {
  background-image: url();
  background-size: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/images/future.jpeg");
}

#pillar-2 {
  background-image: url();
  background-size: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/images/innovation.png");
  background-repeat: no-repeat;
}

#pillar-3 {
  background-image: url();
  background-size: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/images/clean-energy.jpg");
}

#pillar-4 {
  background-image: url();
  background-size: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/images/mobility.jpg");
}

#pillar-5 {
  background-image: url();
  background-size: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/images/smart-solutions.jpg");
}

#pillar-6 {
  background-image: url();
  background-size: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("./assets/images/happiness.jpg");
}

/* Custom page CSS - MOBILE first */
body > .container {
  padding: 60px 15px 0;
}

.container .text-muted {
  margin: 15px 0;
  text-align: start;
}

.card-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: block;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: min-content;
}
.card-flex-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: min-content;
  padding-right: 0;
}
.card-flex-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: min-content;

  background-color: #ffffcc;
  border-radius: 5px;
  margin-bottom: 1em;
  margin-right: 0;
  padding: 1em;
}
.card-flex-image img {
  opacity: 1;
}
.card-flex-button {
  background-color: white;
  border: 1px solid #333;
  padding: 0.5rem;
  color: #333;
  text-align: center;
  text-decoration: none;
}
.card-flex-button:hover {
  color: #333;
  text-decoration: none;
}
.btn-block {
  display: block;
  width: 100%;
}

/* Custom page CSS - iPad/Desktop  */

@media (min-width: 768px) {
  .container .text-muted {
    text-align: end;
  }
  .card-flex-item {
    width: 33.33%;
    padding-right: 1em;
  }
  .card-flex-item:nth-child(3n) {
    padding-right: 0;
  }
  .card-flex-image img {
    opacity: 0.8;
  }
  .card-flex-wrapper:hover {
    cursor: pointer;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 20px 0px;
  }
  .card-flex-wrapper:hover .card-flex-image img {
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  }
  .card-flex-wrapper:hover .card-flex-button {
    background-color: #ffffcc;
  }
}

/* Transition */
.slide-up-appear {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease;

  &.slide-up-appear-active {
    opacity: 1;
    transform: translateY(0px);
  }
}
.impact-head {
  margin: 100px;
}

.team {
  background-color: black;
  padding-top: 100px;
}

.sections-bottom {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  line-height: 0;
}

.sections-bottom svg {
  position: relative;
  display: block;
  // width: calc(100% + 1.3px);
  width: 100%;
  height: 100px;
  transform: rotateY(180deg);
}

.sections-bottom .shape-fill {
  fill: var(--light-grey);
}

.custom-shape-divider-top-1617567759 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1617567759 svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 500px;
}

.custom-shape-divider-top-1617567759 .shape-fill {
  fill: #2e6659;
}
/** For mobile devices **/
@media (max-width: 767px) {
  .sections-bottom svg {
    width: calc(118% + 1.3px);
    height: 83px;
  }
}

.navbar {
  box-shadow: "none";
  padding: 30;
  position: "absolute";
  margin: "auto";
  background-color: "transparent";
}

.custom-shape-divider-top-1618082119 {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 2;
  justify-content: flex-end;
}

.custom-shape-divider-top-1618082119 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1618082119 .shape-fill {
  fill: "black";
}

.section-block {
  display: flex;
  text-align: left;
}

.section-block-text {
  width: 50%;
}

.MuiButtonBase-root {
  border-radius: 50px !important;
}

/* Sponsors Page */

.sponsors {
  background-color: var(--white);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  z-index: 1;
}

.sponsors-body {
  background-color: #ffffff;
  font-weight: bold;
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;

  border-radius: 20px;
}

.sponsors-heading {
  width: 20%;
  justify-content: center;
  font-weight: 700;
}

.sponsors-body > hr {
  max-width: 50vw;
  /* border: solid 1px #009877; */
  border: none;
  margin-bottom: 20px;
  background: linear-gradient(
    to left,
    #00987700,
    #009877 25%,
    #009877 75%,
    #00987700 100%
  );
  height: 3px;
}

#sponsors-platinum {
  font-size: 2.5em;
  color: rgb(59, 201, 194);
}

#sponsors-gold {
  font-size: 2em;
  color: rgb(226, 226, 26);
}

#sponsors-silver {
  font-size: 1.75em;
  color: rgb(167, 168, 168);
}

#sponsors-bronze {
  font-size: 1.5em;
  color: rgb(243, 162, 86);
}

#sponsors-supporters {
  font-size: 1em;
  color: rgb(0, 0, 0);
}

.sponsors-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  margin-bottom: 100px;
  max-width: 1000px;
}

.sponsors-images img {
  width: auto;
  height: auto;
}

.sponsors-images > a {
  display: flex;
  border-radius: 10px;
  transition: transform 0.2s ease;
  transform: scale(1);
}

.sponsors-grid > a {
  display: flex;
  border-radius: 10px;
  transition: transform 0.2s ease;
  transform: scale(1);
}

.sponsors-grid > a:hover {
  display: flex;
  transform: scale(1.1);
}

.sponsors-images > a:hover {
  display: flex;
  transform: scale(1.1);
}

.sponsors-platinum-image {
  max-height: 175px;
  max-width: 475px;
  padding: 20px;
  margin: auto;
}

.sponsors-gold-image {
  max-height: 150px;
  max-width: 350px;
  // padding: 20px;
  margin: auto;
}

.sponsors-silver-image {
  // max-height: 100px;
  max-width: 250px;
  max-height: 125px;
  padding: 20px;
  margin: auto;
}

.sponsors-bronze-image {
  max-height: 100px;
  max-width: 200px;
  padding: 15px;
  margin: auto;
}

.sponsors-supporters-image {
  max-height: 100px;
  max-width: 150px;
  padding: 10px;
  margin: auto;
}

.one {
  border: 1px solid rgb(222, 228, 222);
  width: 29%;
  float: left;
  height: 100px;
}

.two {
  border: 1px solid blue;
  width: 69%;
  float: right;
  height: 100px;
}

.Countdown {
  text-align: center;
  font-size: 5em;
  display: flex;
  flex-direction: row;
}


@media (max-width: 767px) {
  .one {
    position: relative;
    top: 110px;
    width: 100%;
  }
  .two {
    position: relative;
    top: -100px;
    width: 100%;
  }


}

#buttonScrollToTop {
  border-radius: 20px;
  background-color: var(--light-green);
  padding: 10px;
}


.timer-wrapper {
  display: flex;
  justify-content: center;
}

.time-wrapper {
  position: relative;
  width: 180px;
  height: 160px;
  font-size: 30px;
  font-family: "Montserrat";
}

.time-wrapper .time {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.2s;
}

.time-wrapper .time.up {
  opacity: 0;
  transform: translateY(-100%);
}

.time-wrapper .time.down {
  opacity: 0;
  transform: translateY(100%);
}

.Countdown{
  display: flex; 
  flex-direction: row-reverse; 
  
}